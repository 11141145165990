<template>
    <RightDrawer title="拜访详情" :visible.sync="visible">
        <template slot="content">
            <a-descriptions>
                <a-descriptions-item :span="3" label="人员">{{details.username}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="标签">
					<span v-for="item in type_list" :key="item.key" v-show="item.key == details.type">
						{{item.value}}
					</span>
				</a-descriptions-item>
				<a-descriptions-item :span="3" label="客户名称">{{details.customer}}</a-descriptions-item>
				<a-descriptions-item :span="3" label="客户地址">{{details.customer_address}}</a-descriptions-item>
				<a-descriptions-item :span="3" label="城乡类型">{{details.town_type}}</a-descriptions-item>
				<a-descriptions-item :span="3" label="乡镇名称">{{details.town}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="拜访时长">{{details.duration}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="到达时间">{{details.arrive_time}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="到达定位">{{details.arrive_address}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="结束时间">{{details.leave_time}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="结束定位">{{details.leave_address}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="备注">{{details.remark}}</a-descriptions-item>
            </a-descriptions>
            <a-divider />
            <a-descriptions>
                <a-descriptions-item :span="1" label="店招"></a-descriptions-item>
                <a-descriptions-item :span="2">
                    <LookImages :list="details.visit_data.signboard" :is_img="true"></LookImages>
                </a-descriptions-item>
				<a-descriptions-item :span="1" label="堆箱"></a-descriptions-item>
                <a-descriptions-item :span="2">
                    <LookImages :list="details.visit_data.box" :is_img="true"></LookImages>
                </a-descriptions-item>
				<a-descriptions-item :span="1" label="冰冻化"></a-descriptions-item>
                <a-descriptions-item :span="2">
                    <LookImages :list="details.visit_data.freezing" :is_img="true"></LookImages>
                </a-descriptions-item>
                <a-descriptions-item :span="1" label="货架陈列"></a-descriptions-item>
                <a-descriptions-item :span="2">
                    <LookImages :list="details.visit_data.shelves" :is_img="true"></LookImages>
                </a-descriptions-item>
                <a-descriptions-item :span="1" label="广宣物料"></a-descriptions-item>
                <a-descriptions-item :span="2">
                    <LookImages :list="details.visit_data.materials" :is_img="true"></LookImages>
                </a-descriptions-item>
            </a-descriptions>
        </template>
    </RightDrawer>
</template>

<script>
let type_list = [
    {
        key: 0,
        value: '计划拜访'
    },
    {
        key: 1,
        value: '临时拜访'
    },
    {
        key: 2,
        value: '指定拜访'
    },
    {
        key: 3,
        value: '巡查'
    },
];
import { visitInfo } from "@/api/customer";
import RightDrawer from "@/components/RightDrawer";
import LookImages from "@/components/LookImages";
export default {
	components:{
		RightDrawer,
		LookImages
	},
	props:{
		
	},
    data() {
        return {
			visible:false,
			type_list,
            details: {
                visit_data: {

                },
            },
        }
    },
    methods: {
        get_details(item, index) {
            visitInfo({
                data: {
                    id: item.id
                }
            }).then(res => {
				this.details = res.data;
				this.visible = true;
            })
        },
    }

}
</script>